import './TrendingProject.css'
import { useNavigate } from "react-router-dom";
import { useState,useEffect,useContext } from 'react';
import { UserContext } from '../../../UserContext/UserContext';
import BackendUrlRef from '../../../config';
import ProgressBar from './Progressbar/Progressbar';
const TrendingProject = ({ saleDetails, id }) => {
    const {user} = useContext(UserContext)
    const [yourFill, setYourFill] = useState(0)
    const navigate = useNavigate();

    const getSalePage = () => {
        console.log(id);

        navigate(`/project/${id}`);
    };
    console.log(saleDetails)
    useEffect(() => {
        async function getYourFill() {
            if (user) {

                const res = await fetch(`${BackendUrlRef}/referrals/${saleDetails.saleAddress}/${user._id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                const data = await res.json()
                setYourFill(data.total_amount)
            }
        }
        getYourFill()
    }
    , [user])


    return (
        <div className='flex flex-col items-center gap-y-3 w-full'>

            <div className="flex items-center gap-x-3 w-full">
                <img className='w-[50px] h-[50px] rounded-md' src={saleDetails.image} alt='logo' />
                <div className="projectCard w-full">
                    <div className="flex font-medium">
                        <h5>{saleDetails.token.name}</h5>
                        <h5>{"$"}</h5>
                        <h5>{saleDetails.token.tokenSymbol}</h5>

                    </div>
                    <ProgressBar fillPercent={saleDetails.filledPercent} yourFill={yourFill} hardCap={saleDetails.hardCap} />
                    <div className="w-full flex gap-x-3 text-sm mt-1">
                        <div><span style={{
                            color: "#00783f",
                            marginRight: "1px"

                        }}>&#9632;</span>Your Fill</div>
                        <div><span style={{
                            color: "#ffbb1c",
                            marginLeft: "1px"
                        }}>&#9632;</span>Total Fill</div>
                        <div><span style={{
                            color: "#dc2626"
                        }}>&#9632;</span>Hard Cap</div>
                    </div>

                </div>

            </div>
            <button onClick={getSalePage} className=" text-white bg-rb-green rounded-lg px-2 py-1">
                PROMOTE
            </button>

        </div>
    )
}
export default TrendingProject