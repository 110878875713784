import React from "react";
import "./Leadership.css"; // Make sure to create this CSS file in the same directory
import { useEffect, useState,useContext } from "react";
import { UserContext } from "../../UserContext/UserContext";
import BackendUrlRef from "../../config";


const classNames = [
  "bg-gradient-to-r from-rose-400 to-red-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-red-500 to-orange-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-pink-500 to-rose-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-amber-500 to-pink-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-emerald-400 to-cyan-400 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-teal-400 to-yellow-200 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-violet-200 to-pink-200 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-lime-400 to-lime-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-blue-400 to-blue-500 w-8 h-8 rounded-full",
  "bg-gradient-to-r from-amber-200 to-yellow-400 w-8 h-8 rounded-full",
];


const LeaderShip = () => {
  const [data, setData] = useState([]);
  const { user } = useContext(UserContext);
  useEffect(() => {

    const fetchData = async () => {
      const res = await fetch(`${BackendUrlRef}/topten`, {
        headers: {
          Authorization:
            "Bearer " + user.token,
          accept: "application/json",
        },
      });

      const data = await res.json();
      setData(data);
      console.log(data);
    };
    fetchData();
  }, []);
  return (
    <div className="w-full">
      <div className="w-full flex font-semibold text-xl">
        <div className="w-[30%]">Rank</div>
        <div className="w-[60%]">Ambassador</div>
        <div className="w-[10%] text-left">Raise</div>
      </div>
      <div className="flex flex-col gap-y-5 w-full font-medium mt-5">
        {Object.entries(data).map(([userId, userObj], index) => (
          <div key={index} className="w-full flex gap-y-5">
            <div className="w-[30%]">{index + 1}</div>
            <div className="flex items-center w-[60%] gap-x-3">
              <div className={classNames[index]} />
              {userObj.name} {/* Accessing the username */}
            </div>
            <div className="w-[10%] text-left">
              {userObj.amount} {/* Accessing the amount */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaderShip;
