import React from 'react';

const ProgressBar = ({ fillPercent, yourFill, hardCap }) => {
  // Assuming the total progress bar length represents 100 units (or 100%)
  const progressBarTotal = hardCap;

  const yourFillPercent = (yourFill / progressBarTotal) * 100;

  // Calculate the total value of all provided segments
  const filledTotal = fillPercent + yourFillPercent;

  // Calculate the unfilled portion of the progress bar
  // const unfilledSegment = progressBarTotal - filledTotal;

  return (
    <div className="relative h-4 bg-[#dc2626] rounded-lg overflow-hidden">
      {fillPercent > 0 && (
      <div className="absolute top-0 left-0 h-full bg-[#ffbb1c]" style={{ width: `${fillPercent}%` }}></div>
      )}
      {yourFillPercent > 0 && (
      <div className="absolute top-0 left-0 h-full bg-[#00783f]" style={{ width: `${yourFillPercent}%` }}></div>
      )}
      {/* <div className="absolute top-0 left-0 h-full bg-[#dc2626]" style={{ width: `${unfilledSegment}%` }}></div> */}
    </div>
  );
};

export default ProgressBar;
