import calculateTimeRemaining from "../../../utils/calcTime";
import "./AvailableProjectCard.css";
import { useNavigate } from "react-router-dom";
const AvailableProjectCard = (props) => {
  const navigate = useNavigate();
  const routeChange = () => {
    const path = props?.apiResult?._id;

    navigate(path);
  };

  return (
    <div className="flex w-full gap-5 border-b-2 border-black py-5">
      <img
        className="w-20 h-20 object-cover"
        src={props?.apiResult?.sale?.image}
        alt="logo"
      />
      <div className=" flex flex-col gap-3 w-full underline">
        <div className="flex justify-between">
          <div className="flex md:text-xl gap-2 font-bold">
            <h5>{props?.apiResult?.sale?.token?.name}</h5>
            <h5>{"$" + props?.apiResult?.sale?.token?.tokenSymbol}</h5>
          </div>
          <div className="text-[#00783f] md:text-xl font-bold">
            <h4>{"300 ETH HC"}</h4>
          </div>
        </div>
        <div className=" text-xs md:text-lg overflow-x-scroll w-[] md:w-auto">
          <h5>{props?.apiResult?.sale?.description}</h5>
        </div>
        <div className="flex justify-between">
          <button
            className="bg-[#00783f] text-white py-2 px-10 w-max border-2 rounded-none border-white lg:text-lg font-bold underline"
            onClick={routeChange}
          >
            PROMOTE
          </button>
          <div className="flex flex-col font-bold text-xs">
            <h5 className="">Raise ends:</h5>
            <h5>{calculateTimeRemaining(props?.apiResult?.sale?.endDate)}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AvailableProjectCard;
