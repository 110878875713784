import React from "react";
import BaseLayout from "../layout";
import { UserContext } from "../../UserContext/UserContext";
import { useContext, useState } from "react";
import BackendUrlRef from "../../config";
import { toast } from "react-toastify";
export default function Profile() {
  const { user, setUser } = useContext(UserContext);
  console.log(user);

  const [name, setName] = useState(user["name"]);
  const [email, setEmail] = useState(user["email"]);
  const [username, setUsername] = useState(user["userName"]);
  const [wallet, setWallet] = useState(user["wallet"]);
  const [edit, setEdit] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${BackendUrlRef}/users/${user["id"]}?userName=${username}&wallet=${wallet}&name=${name}&email=${email}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${user["token"]}`,
          },
        }
      );
      const data = await response.json();
      toast.success("Profile updated successfully");
      //   update local storage
      const newUser = {
        ...user,
        userName: username,
        wallet: wallet,
        name: name,
        email: email,
      };
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("Profile update failed");
    }
  };

  return (
    <BaseLayout underline={false}>
      <div>
        <div className="bg-white relative shadow rounded-lg w-5/6 md:w-5/6  lg:w-4/6 xl:w-3/6 mx-auto">
          <div className="flex justify-center">
            {/* <img src="https://avatars0.githubusercontent.com/u/35900628?v=4" alt="" className="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110"> */}
          </div>

          <div className="mt-16">
            <h1 className="font-bold text-center text-3xl text-gray-900">
              Hi {user["name"]} 👋
            </h1>
            <p className="text-center text-sm text-gray-400 font-medium">
              {user["email"]}
            </p>

            <div className="my-5 px-6 w-full">
              <button
                onClick={() => setEdit(!edit)}
                className=" block rounded-lg text-center font-medium leading-6 w-full py-3 bg-[#00783f]  text-white"
              >
                Edit Your Profile ✏️
              </button>
            </div>

            <div className="w-full">
              <div className="mt-5 w-full flex flex-col  overflow-hidden text-sm text-left px-4">
                <label className="text-gray-600">Name</label>
                <hr />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={!edit}
                  className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3  block hover:bg-gray-100 transition duration-150"
                />

                <label className="text-gray-600">Email</label>
                <hr />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={!edit}
                  className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3  block hover:bg-gray-100 transition duration-150"
                />

                <label className="text-gray-600">Username</label>
                <hr />
                <input
                  value={username}
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={!edit}
                  className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3  block hover:bg-gray-100 transition duration-150"
                />

                <label className="text-gray-600">Wallet</label>
                <hr />
                <input
                  placeholder="Wallet"
                  value={wallet}
                  onChange={(e) => setWallet(e.target.value)}
                  disabled={!edit}
                  className="w-full border-t border-gray-100 text-gray-600 py-4 pl-6 pr-3  block hover:bg-gray-100 transition duration-150"
                />
                {edit&&(
                <button
                  onClick={handleSubmit}
                  className="w-full bg-[#00783f]  py-4 mt-5 rounded-lg text-white"
                >
                  Save
                </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
