import TrendingProject from "./TrendingProject/TrendingProject"
import { useState, useEffect } from "react"
import './Trending.css'
const Trending = () => {
    const [detail, setDetail] = useState()


    const getTrendingProjects = async () => {
        const res = await fetch("https://protocol-api.arborswap.org/api/sale")
        const data = await res.json()
        const my_arr = []
        // Filter first 3 projects
        for (let i = 0; i < data.length; i++){
            if (data[i].isTrending){
                my_arr.push(data[i])
            }
        }

        setDetail(my_arr)
    }

    useEffect(() => {
        if (!detail){
            getTrendingProjects()
        }
    }, [])
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3  gap-y-7 gap-x-8">
            {detail && detail.map((d,index) => (
                <TrendingProject key={index} saleDetails={d?.sale} id={d?._id} />
            ))}
        </div>
    )
}
export default Trending