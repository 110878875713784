import { useState } from "react"
import { useNavigate } from 'react-router-dom';
import BackendUrlRef from "../../config";

const Registration = (props) => {
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [name, setName] = useState('')
    const [status, setStatus] = useState(false)
    const handleSubmit =   (e) => {
        e.preventDefault()
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const urlencoded = new URLSearchParams();

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
        };

        fetch(`${BackendUrlRef}/users?name=${name}&email=${email}&password=${pass}`, requestOptions)
        .then((response) => response.text())
        .then((result) =>{
            setStatus(true)
        } )
        .catch((error) => console.error(error));
         
        
    }
    const navigate = useNavigate();

    const routeChange = () => {
        let path = '/';
        navigate(path);
    };
    return (
         <div className="Register">
            {!status && <div className="auth-form-container">
                    <h2>Register</h2>
                <form className="register-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Full Name</label>
                    <input value={name} className="text-black"  onChange={(e) => setName(e.target.value)} type="text" placeholder="John Doe" name="text" id="name" required/>
                    <label htmlFor="email">Email</label>
                    <input value={email} className="text-black"  onChange={(e) => setEmail(e.target.value)} type="email" placeholder="youremail@gmail.com" name="email" id="email" required/>
                    <label htmlFor="password">Password</label>
                    <input value={pass} className="text-black" onChange={(e) => setPass(e.target.value)} type="password" placeholder="**********" name="password" id="password" required/>
                    <button type="submit">Register</button>

                </form>
                <button className='link-btn' onClick={routeChange}>Already have an Account? Log In</button>
            </div>}
            {
                status && 
                <div className="w-1/5 h-[100px] border-solid border-2">
                    <h2 className="mt-4">Successfully Registered!</h2>
                    <button className='border-1 w-2/5 h-0 bg-rb-white text-rb-black text-center mt-1 text-base pt-[10px] pb-[30px]' onClick={routeChange}>Log In</button>
                </div>
            }
        </div>
    )
}


export default Registration