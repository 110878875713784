
import { Route, Routes, BrowserRouter, useRoutes } from "react-router-dom";
import WebRouter from "./route";
import { UserProvider } from "./UserContext/UserContext";
import React from "react";
import "./App.css";
import Middleware from "./middleware/middleware";

function App() {

  return (
    <UserProvider>
      <Middleware>
        <WebRouter />
      </Middleware>
    </UserProvider>
  );
}

export default App;
