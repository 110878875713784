const data = [
    { Project: 'SMIND', Imp: 1256, Clicks: 26, Commission: '$51.250' },
    { Project: 'SLARS', Imp: 1256, Clicks: 25, Commission: '$1.250' },
    { Project: 'TOOINS', Imp: 1236, Clicks: 25, Commission: '$1.250' },
    { Project: 'SELM', Imp: 1236, Clicks: 25, Commission: '$1.250' },
];

const ReportTable = () => {
        const totals = {
        Project: 'Total',
        Imp: data.reduce((acc, curr) => acc + curr.Imp, 0),
        Clicks: data.reduce((acc, curr) => acc + curr.Clicks, 0),
        Commission: data.reduce((acc, curr) => {
            const commission = parseFloat(curr.Commission.replace(/[^\d.-]/g, ''));
            return acc + commission;
        }, 0).toFixed(3)
    };
    return (
        <div>
            <div className="border border-gray-300 rounded-md overflow-hidden md:text-[16px] text-[10px]">
                <li className="flex justify-between bg-[#3c3c3c] text-white py-2">
                    <span className="w-1/4 px-2 md:px-4">Project</span>
                    <span className="w-1/4 px-2 md:px-4">Imp</span>
                    <span className="w-1/4 px-2 md:px-4">Clicks</span>
                    <span className="w-1/4 px-2 md:px-4">Commission</span>

                </li>
                {data.map((item, index) => (
                    <li key={index} className={`flex justify-between py-2 font-normal ${index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'}`}>
                        <span className="w-1/4 px-2 md:px-4">{item.Project}</span>
                        <span className="w-1/4 px-2 md:px-4">{item.Imp}</span>
                        <span className="w-1/4 px-2 md:px-4">{item.Clicks}</span>
                        <span className="w-1/4 px-2 md:px-4">{item.Commission}</span>
                    </li>
                ))}
                {/* totals of all rows */}
                <li className="flex justify-between bg-[#3c3c3c] py-2 text-white">
                    <span className="w-1/4 px-2 md:px-4">{totals.Project}</span>
                    <span className="w-1/4 px-2 md:px-4">{totals.Imp}</span>
                    <span className="w-1/4 px-2 md:px-4">{totals.Clicks}</span>
                    <span className="w-1/4 px-2 md:px-4">{totals.Commission}</span>
                </li>



            </div>
        </div>
    );
};

export default ReportTable;
