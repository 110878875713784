import React from "react";
import { useRoutes } from "react-router-dom";
import Login from "../components/Login/Login";
import Project from "../components/Project/Project";
import ProjectDetail from "../components/ProjectDetail/ProjectDetail";
import Report from "../components/Report/Report";
import Registration from "../components/Registration/Registration";
import Dashboard from "../components/Dashboard/Dashboard";
import Profile from "../components/Profile/Profile";

export default function WebRouter() {
  let routes = useRoutes([
    { path: "login", element: <Login /> },
    { path: "register", element: <Registration /> },
    { path: "/", element: <Dashboard /> },
    { path: "project", element: <Project /> },
    { path: "project/:id", element: <ProjectDetail /> },
    { path: "report", element: <Report /> },
    { path: "profile", element: <Profile /> },
  ]);
  return routes;
}
