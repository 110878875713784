import React from "react";
import { useEffect, useState } from "react";
import BackendUrlRef from "../../config";
const Earning = ({ header, userId }) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!userId) return;
    fetch(BackendUrlRef + `/referrals-freq/${header}/${userId}`, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setAmount(data.total_amount);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [header, userId]);

  return (
    <div className="p-4 lg:p-8 rounded-lg">
      <h4 className="uppercase font-medium">{header}</h4>
      <h1 className="font-semibold text-2xl text-rb-green">{"$" + amount}</h1>
    </div>
  );
};

export default Earning;
