import AvailableProjectCard from "./AvailableProjectCard/AvailableProjectCard";
import { useState, useEffect } from "react";
import "./AvailableProject.css";

const AvailableProject = () => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const activeSales = [];
      try {
        const response = await fetch(
          "https://protocol-api.arborswap.org/api/sale"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        const data = await response.json();
        console.log(data);
        for(let i=0; i<data.length; i++){
          if(data[i].sale.status !== "Ended" && data[i].sale.isReferral){
            activeSales.push(data[i]);
          }
        }
        console.log(activeSales);
        setDetails(activeSales);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="w-full">
      {details.map((detail, index) => (
        <div key={index}>
          <AvailableProjectCard apiResult={detail} />
          {index !== details.length - 1 && <hr />}
        </div>
      ))}
    </div>
  );
};

export default AvailableProject;
