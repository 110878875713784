import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackendUrlRef from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer,toast } from "react-toastify";
const Login = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = fetch(`${BackendUrlRef}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "",
        username: email,
        password: pass,
        scope: "",
        client_id: "",
        client_secret: "",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          toast.error("Invalid credentials");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle data returned by the API
        const expiryTime = new Date(new Date().getTime() + 40 * 60000); //40 minutes
        // add expiryTime to data.user
        data.user.expiryTime = expiryTime;
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });

    console.log(response);
  };
  
  const navigate = useNavigate();

  const routeChange = () => {
    let path = "/register";
    navigate(path);
  };
  return (
    <div className="Login">
      <div className="auth-form-container">
        <h2>Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            value={email}
            className="text-black"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="youremail@gmail.com"
            name="email"
            id="email"
            required
          />
          <label htmlFor="password">Password</label>
          <input
            value={pass}
            className="text-black"
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="**********"
            name="password"
            id="password"
            required
          />
          <button type="submit">Log In</button>
        </form>
        <button className="link-btn" onClick={routeChange}>
          Don't have an account? Register here
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
