import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../components/Login/Login";
import Register from "../components/Registration/Registration";
import { UserContext } from "../UserContext/UserContext";

export default function Middleware({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const currentTime = new Date();
      const expiryTime = new Date(user["expiryTime"]);
      if (expiryTime > currentTime) {
        setLoggedIn(true);
        setUser(user);
      } else {
        localStorage.removeItem("user");
        setUser(null);
      }
    } else {
      if (location.pathname === "/register") {
        return;
      }
      navigate("/");
    }
  }, []);

  if (loggedIn) {
    return <div className="w-full">{children}</div>;
  } else {
    if (location.pathname === "/register") {
      return <Register />;
    } else {
      return <Login />;
    }
  }
}
