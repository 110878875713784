import Sidebar from "../Sidebar/Sidebar";
import "./Dashboard.css";
import Earning from "../Earning/Earning";
import Trending from "../Trending/Trending";
import Leadership from "../LeaderShip/Leadership";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext/UserContext";
import Topbar from "../Topbar/Topbar";
import BaseLayout from "../layout";
const Layout = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  // useEffect(() => {
  //     if (user === null) {
  //         //navigate('/login')
  //     }
  // }, [])
  console.log(user);

  return (
    <BaseLayout>
      <div className="flex justify-center">
        <div className="w-full lg:w-4/5 px-4 lg:px-0">
          <div className="mt-8 lg:mt-0">
            <h2 className="font-bold text-2xl">Your Earning</h2>
            <div className="grid grid-cols-3 gap-x-10 border-b border-b-rb-black">
              <Earning header={"daily"} userId={user?._id} />
              <Earning header={"weekly"} userId={user?._id} />
              <Earning header={"monthly"} userId={user?._id} />
            </div>
          </div>
          <div className="mt-10">
            <h2 className="font-bold text-2xl">Trending Projects</h2>
            <div className="trending">
              <Trending />
            </div>
          </div>
          <div className="mt-14">
            <h2 className="font-bold text-2xl mb-5">Leader Board</h2>
            <Leadership />
          </div>
          {/* <div className="referral mt-10 font-medium">
            <span className="font-bold underline">Note:</span>
            <br />
            <span>Earn 2% by Referring KOLs</span>
            <p>
              You earn 2% of the revenue generated by KOLs who sign up under
              using this link
            </p>
          </div> */}
        </div>
      </div>
    </BaseLayout>
  );
};
export default Layout;
