import React, { useContext, useEffect, useState } from 'react'
import { FaCircleUser } from "react-icons/fa6";
import { UserContext } from '../../UserContext/UserContext';
import { MdLogout } from 'react-icons/md';
const navItems = [
  {
    id: 1,
    name: 'Dashboard',
    link: '/',
  },
  {
    id: 2,
    name: 'Project',
    link: '/project',
  },
  {
    id: 3,
    name: 'Report',
    link: '/report',
  },
]


export default function Topbar({Logout} ) {
  const { user } = useContext(UserContext)
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (user) {
      // if username then username otherwise name
      setUsername(user.userName ? user.userName : user.name)
    }
  }, [user])

  return (
    <div className='w-full h-[60px] flex gap-x-3 justify-end lg:py-3 lg:px-8 items-center'>
      <div className='w-full h-[60px] fixed items-center text-rb-white text-xl px-8 font-medium flex justify-between bg-rb-black lg:hidden text-[16px] sm:text-[20px]'>
        {navItems.map((navItem) => (
          <span key={navItem.id} className='hover:text-rb-green'><a href={navItem.link}><i className="fas "></i>{navItem.name}</a></span>
        ))}
        <span onClick={Logout} className='hover:text-rb-green'><MdLogout className='text-xl' /></span>
      </div>

      <div className='hidden lg:flex gap-x-3 items-center'>
        <FaCircleUser className='text-rb-green text-xl lg:text-3xl' />

        <span className='text-sm lg:text-xl font-medium '>
          Hello <u className='text-rb-green'>{username}</u>!</span>
      </div>
    </div>
  )
}
