import "./Sidebar.css";
import TwitterSVG from "../../svgs/twitter";
import InstagramSVG from "../../svgs/instagram";
import TelegramSVG from "../../svgs/telegram";

const socials = [
  {
    id: 1,
    icon: <TelegramSVG className="fill-rb-white" />,
    link: "https://t.me/arborswap",
  },
  {
    id: 2,
    icon: <TwitterSVG className="fill-rb-white" />,
    link: "https://twitter.com/arborswap_defi?s=21&t=oo8B0OfgInd080XRLyhVwg",
  },
  {
    id: 3,
    icon: <InstagramSVG className="fill-rb-white" />,
    link: "https://www.instagram.com/arborswapofficial/?igshid=YmMyMTA2M2Y%3D",
  },
];

const navItems = [
  {
    id: 1,
    name: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    name: "Project",
    link: "/project",
  },
  {
    id: 3,
    name: "Report",
    link: "/report",
  },
  {
    id: 4,
    name: "Profile",
    link: "/profile",
  },
];

const Sidebar = ({ Logout }) => {
    return (
    <div className="w-[18%] h-full bg-rb-black fixed py-10  text-rb-white">
      <img className="w-3/4 pl-10" src={"/images/logo.svg"} alt="logo" />
      <div className="h-full pb-10 flex flex-col justify-between">
        <div className="pl-10">
          <ul className="w-full text-left text-xl font-medium flex flex-col gap-y-5">
            {navItems.map((navItem) => (
              <li key={navItem.id} className="hover:text-rb-green">
                <a href={navItem.link}>
                  <i className="fas "></i>
                  {navItem.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-full mt-4 border-t border-t-gray-500 pt-8 pl-10">
          {socials.map((social) => (
            <a
              key={social.id}
              href={social.link}
              className="ml-[14px] first:ml-0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="twitter flex items-center justify-center bg-[#121212] dark:bg-dark-3 w-[34px] h-[34px] rounded-md ">
                {social.icon}
              </div>
            </a>
          ))}
        </div>
        <div className="pl-10">
          <button
            onClick={() => Logout()}
            className="bg-[#00783f] mx-auto text-white py-2 px-10 w-max border-2 rounded-none border-white text-sm md:text-lg  font-bold underline"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
