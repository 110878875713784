import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { UserContext } from "../../UserContext/UserContext";
import { useContext, useState } from "react";
import ReportTable from "./subComponents/reportTable";
import BaseLayout from "../layout";
const Layout = () => {
  // get user
  const { user } = useContext(UserContext);
  const [dateRange, setDateRange] = useState("Today");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  return (
    <BaseLayout>
      <div className="p-10 no-underline font-medium">
        <h2 className="font-bold text-2xl ">Reports</h2>
        {/* date box */}
        <div className="border-2 border-black flex flex-col mt-2 p-2 md:p-10 text-sm md:text-base">
          <div className="flex gap-2">
            Date Range:
            <select
              className="border border-black ml-2"
              onChange={(e) => setDateRange(e.target.value)}
            >
              <option value="1">Today</option>
              <option value="2">Tomorrow</option>
            </select>
          </div>
          <div className="flex gap-2 items-center">
            From:
            <input
              type="date"
              onChange={(e) => setFromDate(e.target.value)}
              className="ring-black"
            />
            To:
            <input
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              className=""
            />
          </div>
          <button className="bg-[#00783f] mx-auto text-white py-2 px-10 w-max border-2 rounded-none border-white text-sm md:text-lg  font-bold underline">
            Generate Report
          </button>
        </div>
        <div className="mt-10">
          <ReportTable />
        </div>
      </div>
    </BaseLayout>
  );
};
export default Layout;
