import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { useContext } from "react";
import { UserContext } from "../../UserContext/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function BaseLayout({ children,underline = true }) {

  const { setUser } = useContext(UserContext);
  async function Logout() {
    try {
      localStorage.removeItem("user");
      setUser(null);
      window.location.reload();
    } catch (error) {
      toast.error("Failed to logout");
    }
  }
  

  return (
    <div className={`flex flex-col ${underline?"underline":""}`}>
      <div className="hidden lg:block">
      <Sidebar Logout={Logout} />
      </div>
      <div className="flex flex-col lg:ml-[18%] mb-10">
        <div className="header w-full ">
          <Topbar Logout={Logout} />
        </div>
        
        {children}
        <ToastContainer />
      </div>
    </div>
  );
}
