import projectLogo from "../../images/project-logo.jpeg";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProjectDetail.css";
import { useContext } from "react";
import { UserContext } from "../../UserContext/UserContext";
import calculateTimeRemaining from "../../utils/calcTime";
import {  toast } from "react-toastify";
import BaseLayout from "../layout";
import { MdOutlineContentCopy } from "react-icons/md";


const ProjectDetail = () => {
  const { user } = useContext(UserContext);

  const [projectSummary, setprojectSummary] = useState();
  const [referralLink, setReferralLink] = useState();
  const { id } = useParams();

  const Projects = () => {
    fetch(`https://protocol-api.arborswap.org/api/sale/${id}`)
      .then((res) => res.json(res))
      .then((d) => setprojectSummary(d));
  };

  useEffect(() => {
    Projects();
  }, []);

  const GenerateReferralLink = () => {
    // generate using above link and user id
    const link = `https://protocol.arborswap.org/launchpad/pools/${id}/${user._id}`;
    setReferralLink(link);
  };

  const clickToCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <BaseLayout>
      <img className="w-full h-[350px] object-fit" src={projectLogo} alt="logo" />
      <div className="flex  gap-5  py-5 mx-auto justify-center mt-10 items-center">
        <img
          className="sm:w-32 sm:h-32 h-20 w-20 object-cover border-2"
          src={projectSummary?.sale.image}
          alt="logo"
        />
        <div className=" flex flex-col gap-2 w-full underline ">
          <div className="flex ">
            <div className="flex text-xl sm:text-2xl gap-2 font-bold">
              <h5>{projectSummary?.sale.token.name}</h5>
              <h5>{"$" + projectSummary?.sale.token.tokenSymbol}</h5>
            </div>
          </div>
          <div className="text-[#00783f] lg:text-xl font-bold">
            <h4>{"300 ETH HC | PAYOUT: 8%"}</h4>
          </div>
          <div className=" text-xs md:text-lg overflow-x-scroll  md:w-auto">
            <h5>{projectSummary?.sale.description}</h5>
          </div>
          <div className="flex ">
            <div className="flex gap-2 font-bold sm:text-lg">
              <h5 className="">Raise ends:</h5>
              <h5>{calculateTimeRemaining(projectSummary?.sale.endDate)}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col px-14 gap-10">
        <div className="">
          <h2 className="font-bold text-lg sm:text-xl">Project Brief:</h2>
          <p className="ml-2 mt-2">
            {projectSummary && projectSummary.sale.description}
          </p>
        </div>
        <div className="links">
          <h2 className="font-bold text-lg sm:text-xl">Links:</h2>
          <div className="ml-2 mt-2">
            <p>
              {projectSummary &&
                projectSummary.sale.website !== "" &&
                "WEB: " + projectSummary.sale.website}
            </p>
            <p>
              {projectSummary &&
                projectSummary.sale.telegram !== "" &&
                "TG: " + projectSummary.sale.telegram}
            </p>
            <p>
              {projectSummary &&
                projectSummary.sale.twitter !== "" &&
                "X: " + projectSummary.sale.twitter}
            </p>
            <p>
              {projectSummary && "LINKTREE: " + projectSummary.sale.website}
            </p>
          </div>
        </div>

        <div className="talkingPoints">
          <h2 className="font-bold text-lg sm:text-xl">Talking Points</h2>
          <li className="text-[#00783f] text-2xl">
            <span className="text-black text-base">FIRST MOVER</span>
          </li>
          <li className="text-[#00783f] text-2xl">
            <span className="text-black text-base">NO SEED SALE</span>
          </li>
          <li className="text-[#00783f] text-2xl">
            <span className="text-black text-base">NO VESTING</span>
          </li>
          <li className="text-[#00783f] text-2xl">
            <span className="text-black text-base">NO WHITELIST</span>
          </li>
        </div>
        <hr />
        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-lg sm:text-xl">Get Graphic Assets:</h2>
          <button className="bg-[#00783f] text-white py-2 px-10 w-max border-2 rounded-none border-white text-lg font-bold underline mx-auto">
            GET ASSETS
          </button>
        </div>
        <hr />
        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-lg sm:text-xl">
            Generate Referral Link:
          </h2>

          <button
            onClick={GenerateReferralLink}
            className="bg-[#00783f] mx-auto text-white py-2 px-10 w-max border-2 rounded-none border-white text-lg font-bold underline"
          >
            GENERATE LINK
          </button>
          {referralLink && (
            <div className="flex justify-center gap-4 items-center">
              <input
                className="text-black text-base border overflow-scroll border-gray-300 rounded-md py-2 px-4 focus:outline-none ring-2 focus:ring-2 focus:ring-green-400 w-[60%]"
                value={referralLink}
                readOnly
              />
                <MdOutlineContentCopy
                onClick={() => clickToCopy(referralLink)}
                className="text-3xl text-[#00783f] cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default ProjectDetail;
