import Sidebar from "../Sidebar/Sidebar";
import AvailableProject from "../AvailableProject/AvailableProject";
import Topbar from "../Topbar/Topbar";
import BaseLayout from "../layout";

const Project = () => {
  return (
    <BaseLayout>
      <div className="flex justify-center">
        <div className="w-4/5">
          <h2 className="font-bold text-2xl mt-10 lg:mt-0">
            Available Projects
          </h2>
          <div className="currentProjects mt-2 lg:mt-10">
            <AvailableProject />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
export default Project;
