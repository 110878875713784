const calculateTimeRemaining = (endDate) => {
    const now = new Date();
    const end = new Date(endDate * 1000);

    // Calculate the difference in milliseconds
    const difference = end - now;
    // if the difference is negative, the end date is in the past, so just show 0 days remaining

    // Calculate the remaining days, hours, and minutes
    if (difference < 0) {
      return "0D 0H 0M";
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}D ${hours}H ${minutes}M`;
  };

export default calculateTimeRemaining;